<template>
    <section class="invoice">
      <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
        <div class="content-wrapper">
          <div class="page-header">
          <h3 class="page-title">
              Bon de commande
          </h3>
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
              <!-- <li class="breadcrumb-item">Mes Réservations</li> -->
              <li class="breadcrumb-item active" aria-current="page">Ma réservation</li>
              </ol>
          </nav>
          </div>
          <div class="row">
              <div class="col-lg-12">
                  <div class="card px-2">
                      <div class="card-body">
                          <div class="container-fluid">
                          <h3 class="text-right my-5"> <p>Référence :</p> MEB-{{ulid}}</h3>
                          <hr> </div>
                          <div class="container-fluid d-flex justify-content-between">
                            <div class="col-lg-3 pl-0">
                                <p class="mt-5 mb-2">
                                </p>
                                <p>Mon échappée Belle,
                                <br>64 avenue Saint Exupéry,
                                <br>81600 GAILLAC.</p>
                            </div>
                            <div class="col-lg-3 pr-0">
                                <p class="mt-5 mb-2 text-right">
                                <b>Facturé à</b>
                                </p>
                                <p class="text-right" v-if="reservation.on_behalf_of">{{reservation.on_behalf_of.name}}
                                <br><span v-if="reservation.on_behalf_of.email">{{reservation.on_behalf_of.email}}</span>
                                <br><span v-if="reservation.on_behalf_of.phone">{{reservation.on_behalf_of.phone}}</span>
                                </p>
                            </div>
                          </div>
                          <div class="container-fluid d-flex justify-content-between">
                            <div class="col-lg-3 pl-0">
                                <p class="mb-0 mt-5" v-if="reservation">Fait le: {{formatDate2(reservation.created_at)}}</p>
                                <p v-if="appointments[0]">Pour le : {{ formatDate2(appointments[0].start_hour) }} à {{getTime(appointments[0].start_hour)}}</p>
                            </div>
                          </div>
                          <div class="container-fluid mt-5 d-flex justify-content-center w-100" v-if="appointments">
                              <!-- tableau réservation -->
                              <div class="table-responsive w-100">
                                  <table class="table" >
                                  <thead>
                                      <tr class="bg-dark text-white">
                                      <th>#</th>
                                      <th>Description</th>
                                      <th class="text-left">Date</th>
                                      <th class="text-left">Durée estimatif</th>
                                      <!-- <th class="text-left">Débute à</th>
                                      <th class="text-left">Se termine à</th> -->
                                      <th class="text-right">Prix</th>
                                      </tr>
                                  </thead>
                                  <tbody >
                                      <tr class="text-right" v-for="(appointment, appointmentId) in appointments" :key="appointmentId">
                                      <td class="text-left">{{appointmentId+1}}</td>
                                      <td class="text-left">{{appointment.service.name}}</td>
                                      <td class="text-left" v-if="appointments">{{formatDate2(appointments[0].start_hour)}}</td>
                                      <td class="text-left">{{calculTimesWithHHMM(appointment.service.duration)}}</td>
                                      <!-- <td class="text-left">{{getTime(appointment.start_hour)}}</td>
                                      <td class="text-left">{{getTime(appointment.end_hour)}}</td> -->
                                      <td class="text-right"> {{appointment.service.price}} €</td>
                                      </tr>
                                  </tbody>
                                  </table>
                              </div>
                          </div>
                          <div class="container-fluid mt-5 w-100">
                          <h4 class="text-right mb-5">Total : ${{roundNumber(total)}}</h4>
                          <hr> </div>
                          <div class="container-fluid w-100 fluid-btn d-flex justify-content-end flex-sm-column flex-md-row">
                              <button class="btn btn-outline-primary float-left mt-4 ml-2"  @click="cancelReservation()">
                                  <i class="fa fa-trash"></i> Annuler
                              </button>
                              <button class="btn btn-primary float-right mt-4 ml-2" @click="print()">
                                  <i class="mdi mdi-printer mr-1"></i>Imprimer
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </section>
  </template>
  
  <script>
  import axios from 'axios'
  import pageLoader from '../components/pageLoader.vue';
  import { priceMixin } from './mixins/price';
  import { dateMixin } from './mixins/date';
  
  export default {
    name: 'reservationDetails',
    mixins:[priceMixin, dateMixin],
    components:{
        pageLoader
    },
    data () {
        return {
            ulid: '',
            orders: '',
            loadershow:true,
            reservation:'',
            appointments: []
  
        }
    },
    computed:{
        total(){
            let total = 0;
            this.appointments.forEach(element => {
                if(element.service){
                    total = total + element.service.price
                }
                if(element.product){
                    total = total + (element.product.price * 1.2) * element.quantity
                }
                if(element.voucher){
                    total = total + element.voucher.price * element.quantity
                }
            }); 
            return total
        }
    },
    methods: {
        getReservation(){
            axios.get(`myreservations/${this.ulid}`)
            .then(resGetOrdersDetails => {
                this.reservation=resGetOrdersDetails.data.result
                for (let appointment of resGetOrdersDetails.data.result.appointments) {
                    this.appointments.push(appointment)
                }
                this.appointments = this.appointments.sort()

                this.loadershow = false
            })
            .catch(errOrdersDetails => console.log(errOrdersDetails))
        },
        cancelReservation(){
            this.$swal({
            title: 'Désirez-vous annuler votre réservation?',
            showCancelButton: true,
            confirmButtonColor: '#0b0b0b',
            cancelButtonColor: '#fff',
            confirmButtonText: 'Valider',
            cancelButtonText: 'Annuler'
        })
        .then((result) => {
            if (result.value) {
            this.loadershow = true;
            axios.put(`myreservations/${this.ulid}`,{
                status: "cancelled"
            })
                .then(()=>{
                this.$router.go(-1)
                })
                .catch()
            }else{
                this.loadershow=false
            }
        })
        }
    },
    mounted () {
        this.getReservation()
    },
    created () {
        this.ulid= this.$route.params.ulid
    },
    beforeCreate() {
        // mettre X-Auth-token sur toutes les requêtes de la page
        axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('token');
    }
  }
  </script>
  <style scoped>
  @media screen and (max-width: 992px) {
      .fluid-btn button{
          width:100% !important;
      }
  }
  </style>